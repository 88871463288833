import { render, staticRenderFns } from "./MaterialesList.vue?vue&type=template&id=98931e54&scoped=true&"
import script from "./MaterialesList.vue?vue&type=script&lang=js&"
export * from "./MaterialesList.vue?vue&type=script&lang=js&"
import style0 from "./MaterialesList.vue?vue&type=style&index=0&id=98931e54&lang=scss&scoped=true&"
import style1 from "./MaterialesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98931e54",
  null
  
)

export default component.exports