export const materiales = {
    tipos: [
        {
            label: "Material",
            value: "material"
        },
        {
            label: "Herramienta",
            value: "herramienta"
        },
        {
            label: "Uniforme",
            value: "uniforme"
        },
    ],
    medidas: [
        {
            label: "Unidades",
            value: "unidades"
        },
        {
            label: "Cajas",
            value: "cajas"
        },
        {
            label: "Tallas",
            value: "tallas"
        },
        {
            label: "Metros",
            value: "metros"
        },
        {
            label: "Litros",
            value: "litros"
        },
        {
            label: "Kilos",
            value: "kilosº"
        },
    ],
    tallas: [
        {
            label: "XS",
            value: "XC"
        },
        {
            label: "S",
            value: "S"
        },
        {
            label: "M",
            value: "M"
        },
        {
            label: "L",
            value: "L"
        },
        {
            label: "XL",
            value: "XL"
        },
        {
            label: "2XL",
            value: "2XL"
        },
        {
            label: "3XL",
            value: "3XL"
        },
        {
            label: "4XL",
            value: "4XL"
        },
        {
            label: "5XL",
            value: "5XL"
        },
    ],
    tallasPantalon: [
        {
            label: "34",
            value: "34"
        },
        {
            label: "36",
            value: "36"
        },
        {
            label: "38",
            value: "38"
        },
        {
            label: "40",
            value: "40"
        },
        {
            label: "42",
            value: "42"
        },
        {
            label: "44",
            value: "44"
        },
        {
            label: "46",
            value: "46"
        },
        {
            label: "48",
            value: "48"
        },
        {
            label: "50",
            value: "50"
        },
        {
            label: "52",
            value: "52"
        },
        {
            label: "54",
            value: "54"
        },
        {
            label: "56",
            value: "56"
        },
        {
            label: "58",
            value: "58"
        },
        {
            label: "60",
            value: "60"
        },
        {
            label: "62",
            value: "62"
        },
    ],
    estados: [
        {
            label: "Activo",
            value: "activo"
        },
        {
            label: "Inactivo",
            value: "inactivo"
        },
        {
            label: "Reparacion",
            value: "reparacion"
        },
        {
            label: "Baja",
            value: "baja"
        }
    ]
}